import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import flatten from 'lodash/flatten';
import styled from 'styled-components';

import Stage from 'ui-kit/stage';
import Section from 'ui-kit/section';
import Container from 'ui-kit/container';
import List from 'ui-kit/list';
import { Headline } from 'ui-kit/typography';
import PreparationStep from 'ui-kit/preperation-step';
import RecipeMeta from 'ui-kit/recipe-meta';
import { RecipeCard } from 'ui-kit/card';
import Slider from 'ui-kit/slider';

import Layout from '../components/layout';

const StyledInput = styled.input`
    border: none;
    border-bottom: 2px solid currentColor;
    border-radius: 0;
    color: inherit;
    max-width: 2em;
    padding: 0;
    margin: 0;
`;

const IngredientsBox = styled(Container)`
    padding: 1.5em;
`;

export default class RecipeTemplate extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            recipesJson: PropTypes.object,
            relatedRecipes: PropTypes.object,
        }).isRequired,
    };

    state = {
        portions: null,
    };

    /**
     * Rendert die Komponente
     */
    render() {
        const {
            data: { recipesJson: recipe, relatedRecipes },
        } = this.props;
        const portions = this.state.portions || recipe.portions; // eslint-disable-line react/destructuring-assignment

        // console.log(relatedRecipes);

        return (
            <Layout title={`Rezepte | ${recipe.title}`} description="Rezepte">
                <Stage
                    image={recipe.image.childImageSharp}
                    bottomShade
                    headline={{
                        text: recipe.title,
                        level: 'h1',
                    }}
                    tag={recipe.category}
                />
                <Section>
                    <Container grid cols={2} gridGap="4em" size="l" layout="4-8">
                        <Container gap={{ small: 'xxxl', medium: 'none' }}>
                            <Headline level="h3" gap="xl" textAlign="center">
                                Zutaten für{' '}
                                <StyledInput
                                    type="number"
                                    value={portions}
                                    min="0"
                                    max="48"
                                    onChange={evt =>
                                        this.setState({
                                            portions: parseInt(evt.currentTarget.value, 10),
                                        })
                                    }
                                />{' '}
                                Portion
                                {portions > 1 ? 'en' : null}
                            </Headline>
                            <IngredientsBox layer={1} radius="m" padded rounded>
                                {recipe.ingredients.map((ingredients, i) => (
                                    <List
                                        key={`ingredients-${ingredients.title}`}
                                        title={ingredients.title}
                                        items={ingredients.items.map(
                                            item =>
                                                `${
                                                    item.amount
                                                        ? (
                                                              (item.amount / recipe.portions) *
                                                              portions
                                                          ).toLocaleString()
                                                        : ''
                                                } ${item.unit ? item.unit : ''} ${item.title}`
                                        )}
                                        gap={i < recipe.ingredients.length - 1 ? 'l' : 'none'}
                                    />
                                ))}
                            </IngredientsBox>
                        </Container>
                        <Container display="flex" direction="column">
                            <Headline level="h2" gap="xxl">
                                Zubereitung
                            </Headline>
                            {recipe.steps.map((step, i) => (
                                <PreparationStep number={i + 1} key={step}>
                                    {step}
                                </PreparationStep>
                            ))}
                            <RecipeMeta alignSelf="flex-end" {...recipe} />
                        </Container>
                    </Container>
                </Section>
                {relatedRecipes && (
                    <Section bg="pattern">
                        <Headline level="h2" gap="l" textAlign="center">
                            Ähnliche Rezepte
                        </Headline>
                        <Slider>
                            {relatedRecipes.edges.map(({ node: relatedRecipe }) => (
                                <RecipeCard
                                    key={relatedRecipe.slug}
                                    layer={2}
                                    image={relatedRecipe.image.childImageSharp}
                                    difficulty={relatedRecipe.difficulty}
                                    time={relatedRecipe.time}
                                    title={relatedRecipe.title}
                                    tag={relatedRecipe.category}
                                    link={`/rezepte/${relatedRecipe.slug}`}
                                    imageCritical
                                />
                            ))}
                        </Slider>
                    </Section>
                )}
                <script
                    type="application/ld+json"
                    /* eslint-disable-next-line */
                    dangerouslySetInnerHTML={{
                        __html: `
                        {
                            "@context": "http://schema.org/",
                            "@type": "Recipe",
                            "name": "${recipe.title}",
                            "recipeIngredient": [
                                "${flatten(recipe.ingredients.map(ingredient => ingredient.items))
                                    .map(item =>
                                        `${item.amount ? item.amount : ''} ${
                                            item.unit ? item.unit : ''
                                        } ${item.title}`.trim()
                                    )
                                    .join('", "')}"
                            ],
                            "recipeInstructions": [
                                "${recipe.steps.join('", "')}"
                            ],
                            "recipeYield": "${recipe.portions} portions",
                            "image":"https://www.suedbayerische-fleischwaren.de${
                                recipe.image.childImageSharp.fluid.src
                            }",
                            "cookTime": "PT${recipe.time}M"
                        }
                    `,
                    }}
                />
            </Layout>
        );
    }
}

export const query = graphql`
    query($slug: String!, $category: String!) {
        relatedRecipes: allRecipesJson(
            filter: { category: { eq: $category }, slug: { ne: $slug } }
            limit: 8
        ) {
            edges {
                node {
                    slug
                    title
                    category
                    difficulty
                    time
                    image {
                        childImageSharp {
                            fluid(maxWidth: 430, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        recipesJson(slug: { eq: $slug }) {
            id
            title
            slug
            image {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            category
            time
            difficulty
            portions
            ingredients {
                title
                items {
                    amount
                    unit
                    title
                }
            }
            steps
        }
    }
`;
