import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { primaryColor, buttonTextColor } from 'helpers/themehelpers';
import { colors } from 'helpers/variables';

/** Wrapper */
const Wrapper = styled.div`
    align-items: flex-start;
    border-bottom: 1px solid ${colors.gray300};
    display: flex;
    margin-bottom: 1.45em;
    padding-bottom: 1.45em;
    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
    }
`;

/** Höhe / Breite / Line-Height für Number */
const numberDimensions = rem(40);

/** Kringel für die Schrittnummer */
const Number = styled.div`
    flex: 0 0 auto;
    background-color: ${primaryColor};
    border-radius: 100%;
    color: ${buttonTextColor};
    font-size: ${rem(20)};
    height: ${numberDimensions};
    margin-right: ${rem(20)};
    text-align: center;
    line-height: ${numberDimensions};
    width: ${numberDimensions};
`;

/**
 * Zeigt einen Zubereitungschritt an
 * @param {number} props.number Zubereitungsschritt-Nummer
 * @param {node} props.children Zubereitungsanweisung
 */
const PreperationStep = ({ number, children }) => (
    <Wrapper>
        <Number>{number}</Number>
        {children}
    </Wrapper>
);

PreperationStep.propTypes = {
    number: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default PreperationStep;
